<template>
  <div class="container">
    <div class="title">账户充值</div>
    <div class="content">
      <div class="form_box">
        <div class="avatar_box">
          <el-avatar
            :size="80"
            :src="$store.state.userInfo.base.head_portrait?$store.state.userInfo.base.head_portrait:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
          ></el-avatar>
          <div
            class="mask"
            @click="$refs.uploadRef.$el.click()"
          >修改头像</div>
          <el-upload
            :http-request="uploadAvatar"
            action="fakeaction"
            class="upload-demo"
            multiple
          >
            <el-button
              slot="trigger"
              size="small"
              type="primary"
              ref="uploadRef"
            >选取文件</el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            ></div>
          </el-upload>
        </div>
        <el-form
          ref="form"
          label-width="100px"
        >
          <el-form-item label="用户名：">
            {{$store.state.userInfo.base.user_name}}
          </el-form-item>
          <el-form-item label="手机号：">
            {{$store.state.userInfo.base.user_mobile}}
            <span
              class="btn"
              @click="$refs.editPhoneNum.openDialog()"
            >去修改 <i class="el-icon-arrow-right"></i></span>
          </el-form-item>
          <!-- <el-form-item label="appid：">
            {{$store.state.userInfo.detail.app_id}}
          </el-form-item> -->
          <el-form-item label="认证状态：">
            <span
              v-if="$store.state.userInfo.detail.auth_status == 0">未实名认证</span>
            <span
              v-else-if="$store.state.userInfo.detail.auth_status == 1">认证中</span>
            <span
              v-else-if="$store.state.userInfo.detail.auth_status == 2">已认证</span>
            <span
              v-else-if="$store.state.userInfo.detail.auth_status == 3">认证失败</span>
            <span
              class="btn"
              v-if="$store.state.userInfo.detail.auth_status == 0 || $store.state.userInfo.detail.auth_status == 3"
              @click="$router.push('/user/auth')"
            >立即认证 <i class="el-icon-arrow-right"></i></span>
            <span
              class="btn"
              v-if="$store.state.userInfo.detail.auth_status == 1 || $store.state.userInfo.detail.auth_status == 2"
              @click="$router.push('/user/auth')"
            >查看详情 <i class="el-icon-arrow-right"></i></span>
          </el-form-item>
          <el-form-item
            label="用户类型："
            v-if="$store.state.userInfo.detail.auth_status == 2"
          >
            {{$store.state.userInfo.detail.auth_status == 1?'个人':'企业'}}
          </el-form-item>
          <el-form-item
            label="认证时间："
            v-if="$store.state.userInfo.detail.auth_status == 2"
          >
            {{$store.state.userInfo.detail.auth_time}}
          </el-form-item>
        </el-form>
      </div>
    </div>
    <EditPhoneNum ref="editPhoneNum"></EditPhoneNum>
  </div>
</template>

<script>
import { uploadFileApi } from '../../../api/common'
import { editAvatarApi } from '../../../api/user'
const EditPhoneNum = () => import('../../../components/EditPhoneNum.vue')

export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {
    EditPhoneNum
  },
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.$store.dispatch('getUserInfo', 1)
  },
  mounted () { },
  // 组件方法
  methods: {

    async uploadAvatar (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      // console.log(formData)
      const res = await uploadFileApi(formData)
      console.log(res)
      if (res && res.code === 0) {
        // this.form.avatar = res.data.url
        const result = await editAvatarApi({ url: res.data.url })
        if (result && result.code === 0) {
          this.$message.success('修改成功')
          const user = this.$store.state.userInfo
          user.base.head_portrait = res.data.url
          this.$store.commit('setUserInfo', user)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    .form_box {
      width: 500px;
      // height: 100px;
      // background-color: pink;
      margin: 20px auto;
      .avatar_box {
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        .mask {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
          cursor: pointer;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .el-form {
        width: 60%;
        margin: 20px auto;
        .el-form-item {
          margin-bottom: 10px;
          .btn {
            color: #527dea;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
